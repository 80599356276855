import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Checkmark from "./check.svg";

const separator = ",";

const Checkbox = ({ value, options, onChange }) => {
  const handleChange = opt => {
    let vals = value.split(separator).filter(a => !!a);
    if (vals.includes(opt)) {
      vals = vals.filter(a => a !== opt);
    } else {
      vals.push(opt);
    }
    onChange(vals.join(separator));
  };

  return options.map(opt => (
    <CheckboxWrapper key={opt}>
      <Label onClick={() => handleChange(opt)}>
        <input type="checkbox" checked={valueIncludes(value, opt)} readOnly />
        <Box>
          <img src={Checkmark} alt="" />
        </Box>
        <span>{opt}</span>
      </Label>
    </CheckboxWrapper>
  ));
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  input {
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  input:checked + div {
    border-color: var(--brandGreen);
    background-color: var(--brandGreen);
  }
  input:checked + div + span {
    color: var(--grey12);
  }
  input:checked + div img {
    opacity: 1;
  }
`;

// Replacement input box
const Box = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--brandGreenRGB), 0.15);
  border: 1px solid var(--brandGreen);
  margin-top: 0.5rem;
  margin-right: 0.5rem;

  &:hover {
    background-color: rgba(var(--brandGreenRGB), 0.35);
  }
  img {
    width: 14px;
    opacity: 0;
    transform: translate(1px, 1px);
  }
  + span {
    margin-top: 0.4rem;
    color: var(--grey05);
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
`;

const valueIncludes = (value, opt) => value.split(separator).includes(opt);
