import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  min-height: 48px;
  font-family: "Brandon Text";
  border-radius: 6px;
  padding: 0 12px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  `;

export const PrimaryButton = styled(Button)`
  background-color: var(--brandGreen);
  color: var(--white);
  font-weight: bold;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0 2px 12px 0 rgba(var(--brandGreenRGB), 0.25);

  &:hover {
    box-shadow: 0 2px 24px 0 rgba(var(--brandGreenRGB), 0.7);
    cursor: pointer;
    border: 1px solid var(--brandGreen);
  }

  &:active {
    background-color: var(--brandGreenDark);
    border-color: var(--brandGreenDark);
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 0 8px rgba(var(--brandGreenRGB), 0.2),
      0 2px 24px 0 rgba(var(--brandGreenRGB), 0.6);
    outline: none;
  }

  &:disabled {
    opacity: 0.2;
  }
`;
