import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { PrimaryButton } from "./buttons";

const NavButtons = ({ next }) => {
  return (
    <Wrapper>
      <PrimaryButton onClick={next}>Continue</PrimaryButton>
    </Wrapper>
  );
};

NavButtons.propTypes = {
  next: PropTypes.func.isRequired,
};

export default NavButtons;

const Wrapper = styled.div`
  width: 100%;

  button {
    margin-left: auto;
  }
`;
