import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Label = ({ index, prompt }) => {
  return (
    <StyledLabel>
      {index}. {prompt}
    </StyledLabel>
  );
};

Label.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  prompt: PropTypes.string.isRequired,
};

export default Label;

const StyledLabel = styled.label`
  color: var(--grey12);
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 0.5rem;
`;
