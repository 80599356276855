import styled from "styled-components";

import Card from "./Card";

export const MessageCard = styled(Card)`
  padding: 6.5rem 8rem;
  max-width: 888px;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 4rem 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }
`;

export const Heading = styled.h1`
  color: var(--grey12);
  font-family: "Brandon Text";
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Icon = styled.div`
  font-size: 5rem;
  line-height: 5rem;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`;

export const Content = styled.div`
  color: var(--grey12);
  font-family: "Brandon Text";
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
  margin: 1.5rem 0 3.5rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-top: 0;
  }
`;
