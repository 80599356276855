import styled from "styled-components";

const SectionTitle = styled.div`
  color: var(--grey12);
  font-size: 32px;
  font-weight: 900;
  margin: 8px 0 16px;
`;

export default SectionTitle;
