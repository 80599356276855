import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Question from "./Question";
import Combobox from "./Combobox";
import RadioButton from "./RadioButton";
import Checkbox from "./Checkbox";
import Input from "./Input";
import Textarea from "./Textarea";
import URLInput from "./URLInput";
import NumberInput from "./NumberInput";

const SectionQuestions = ({
  fieldPrefix,
  questions,
  updateAnswer,
  answers,
  errors,
}) => {
  const fieldID = id => `${fieldPrefix}.${id}`;
  let currentIndex = 1;
  const questionElems = questions
    .filter(spec => {
      if (!spec.visibility) {
        return true;
      }

      return answers[fieldID(spec.visibility.refID)] === spec.visibility.value;
    })
    .map(spec => {
      const index = spec.num || currentIndex++;
      const specID = fieldID(spec.id);
      const eChangeHandler = e => updateAnswer(specID, e.target.value);
      const value = answers[specID] || "";
      switch (spec.type) {
        case "combo":
          return (
            <Question
              key={specID}
              index={index}
              prompt={spec.prompt}
              error={errors[spec.id]}
            >
              <Combobox
                {...spec}
                onChange={v => updateAnswer(specID, v)}
                value={value}
              />
            </Question>
          );
        case "checkbox":
          return (
            <Question
              key={specID}
              index={index}
              prompt={spec.prompt}
              error={errors[spec.id]}
            >
              <Checkbox
                {...spec}
                onChange={v => updateAnswer(specID, v)}
                value={value}
              />
            </Question>
          );
        case "radio":
          return (
            <Question
              key={specID}
              index={index}
              prompt={spec.prompt}
              error={errors[spec.id]}
            >
              <RadioButton {...spec} onChange={eChangeHandler} value={value} />
            </Question>
          );
        case "textarea":
          return (
            <Question
              key={specID}
              index={index}
              prompt={spec.prompt}
              error={errors[spec.id]}
            >
              <Textarea value={value} required onChange={eChangeHandler} />
            </Question>
          );
        case "url":
          return (
            <Question
              key={specID}
              index={index}
              prompt={spec.prompt}
              error={errors[spec.id]}
            >
              <URLInput value={value} required onChange={eChangeHandler} />
            </Question>
          );
        case "number":
          return (
            <Question
              key={specID}
              index={index}
              prompt={spec.prompt}
              error={errors[spec.id]}
            >
              <NumberInput
                value={value}
                onChange={eChangeHandler}
              ></NumberInput>
            </Question>
          );
        default:
          return (
            <Question
              key={specID}
              index={index}
              prompt={spec.prompt}
              error={errors[spec.id]}
            >
              <Input
                value={value}
                type={spec.type}
                pattern="\S+.*"
                onChange={eChangeHandler}
              ></Input>
            </Question>
          );
      }
    });

  return <Questions>{questionElems}</Questions>;
};

SectionQuestions.propTypes = {
  fieldPrefix: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      prompt: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      visibility: PropTypes.shape({
        refID: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  answers: PropTypes.any.isRequired,
  errors: PropTypes.shape({
    id: PropTypes.string,
  }),
};

SectionQuestions.defaultProps = {
  errors: {},
};

export default SectionQuestions;

const Questions = styled.div`
  margin-top: 3.5rem;
`;
