import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classnames from "classnames";

import GreenChevron from "./chevron-green.svg";

const ProgressBar = ({
  totalPages, // num of maxibars
  currentPage, // index of current page
  totalSections, // num of mini sections
  currentSection, // index
  goToSection,
}) => {
  const hasSections = totalSections > 1;
  return (
    <ProgressBarWrapper>
      <MaxiWrapper>
        {Array.from(Array(totalPages)).map((_v, i) => (
          <MaxiBar
            key={i}
            className={classnames({
              current: i === currentPage,
              upcoming: i > currentPage,
              "has-sections": hasSections,
            })}
            onClick={() => goToSection(i, 0)}
          >
            {i === currentPage &&
              (hasSections ? (
                <Section
                  {...{ currentSection, totalSections }}
                  goToSection={secInd => goToSection(i, secInd)}
                />
              ) : (
                arrow
              ))}
          </MaxiBar>
        ))}
      </MaxiWrapper>
    </ProgressBarWrapper>
  );
};

ProgressBar.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalSections: PropTypes.number.isRequired,
  currentSection: PropTypes.number.isRequired,
  goToSection: PropTypes.func.isRequired,
};

export default ProgressBar;

const Section = ({ currentSection, totalSections, goToSection }) => {
  if (totalSections === 0) {
    return null;
  }

  return (
    <MiniSection>
      {Array.from(Array(totalSections)).map((_v, i) => (
        <MiniBar
          key={i}
          className={classnames({
            current: i === currentSection,
            upcoming: i > currentSection,
          })}
          onClick={() => goToSection(i)}
        >
          {i === currentSection && arrow}
        </MiniBar>
      ))}
    </MiniSection>
  );
};

Section.propTypes = {
  totalSections: PropTypes.number.isRequired,
  currentSection: PropTypes.number.isRequired,
  goToSection: PropTypes.func.isRequired,
};

const ProgressBarWrapper = styled.div`
  margin-bottom: 24px;
`;

const Arrow = styled.div`
  height: 1.5rem;
  width: 100%;
  position: absolute;
  top: -1.5rem;
  display: flex;
  justify-content: center;

  object {
    width: 24px;
  }
`;

const arrow = (
  <Arrow>
    <object
      aria-label="arrow"
      type="image/svg+xml"
      className="arrow"
      data={GreenChevron}
    />
  </Arrow>
);

const MaxiWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MaxiBar = styled.div`
  display: flex;
  height: 8px;
  flex-grow: 1;
  max-height: 8px;
  background-color: var(--brandGreen);
  margin-left: 4px;
  position: relative;
  cursor: pointer;

  &.current {
    background-color: var(--grey12);
  }

  &.current.has-sections {
    background-color: var(--grey04);
  }

  &.upcoming {
    background-color: var(--grey03);
  }

  &:first-of-type {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  &:last-of-type {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
`;

const MiniBar = styled.div`
  flex-grow: 1;
  height: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  background-color: var(--brandGreen);
  position: relative;
  cursor: pointer;

  &.current {
    background-color: var(--grey12);
  }

  &.upcoming {
    background-color: var(--grey03);
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

const MiniSection = styled.div`
  position: absolute;
  top: -0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
