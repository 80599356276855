import React from "react";
import PropTypes from "prop-types";
import Input from "./Input";

const URLInput = ({ value, onChange }) => {
  return (
    <Input
      value={value}
      type="url"
      onChange={onChange}
    ></Input>
  )
}

URLInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default URLInput;