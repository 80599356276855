import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Textarea = ({ value, onChange }) => {
  return (
    <TextareaStyling
      value={value}
      type="text"
      onChange={onChange}
    ></TextareaStyling>
  )
}

Textarea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Textarea;

const TextareaStyling = styled.textarea`
  font-family: "Brandon Text", sans-serif;
  box-sizing: border-box;
  height: 112px;
  font-size: 16px;
  border: 1px solid var(--grey04);
  border-radius: 6px;
  padding: 0.75rem 1rem;
  margin-top: .5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  resize: none;
  outline: none;

  &:focus {
    border-color: var(--brandGreen);
    border-radius: 6px;
    box-shadow: 0 0 0 8px rgba(var(--brandGreenRGB), 0.3);
    outline: none;
  }
  &:hover {
    border-color: var(--brandGreen);
  }
  &:visited {
    border-color: var(--grey05);
  }
  &:valid {
    border-color: var(--grey05);
  }
`;
