import React from "react";
import styled, { keyframes } from "styled-components";

const Loading = () => {
  return (
    <Loader>
      <StyledLoader />
      Loading...
    </Loader>
  );
};

export default Loading;

const Loader = styled.div`
  margin-top: 3.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  color: var(--grey12);
`;

const rotation = keyframes`
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
`;
const rotationBack = keyframes`
    0% { transform: rotate(0deg) }
    100% { transform: rotate(-360deg) }
`;

const StyledLoader = styled.div.attrs(() => ({
  size: "84px",
  borderSize: "6px",
}))`
  width: ${p => p.size};
  height: ${p => p.size};
  margin-bottom: 2rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: ${p => p.borderSize} solid;
  border-color: var(--grey12) var(--grey12) transparent transparent;
  animation: ${rotation} 1s linear infinite;
  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: ${p => p.borderSize} solid;
    border-color: transparent transparent rgba(var(--brandGreenRGB), 1)
      rgba(var(--brandGreenRGB), 1);
    width: ${p => p.size} - 8px;
    height: ${p => p.size} - 8px;
    border-radius: 50%;
    animation: ${rotationBack} 0.5s linear infinite;
    transform-origin: center center;
  }
  &:before {
    width: ${p => p.size} - 16px;
    height: ${p => p.size} - 16px;
    border-color: var(--grey12) var(--grey12) transparent transparent;
    animation: ${rotation} 1.5s linear infinite;
  }
`;
