import assessment from "./assessment-spec";

/* global process */
const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://university.growthtools.com"
    : "http://localhost:3001";

const saveAssessment = async body => {
  const url = `${baseUrl}/webhooks/our-assessment/${body.clientSlug}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const params = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  };
  const request = new Request(url, params);

  return fetch(request).then(response => {
    if (!response.ok) {
      return assessment;
    }

    return response.json();
  });
};

const getAssessment = async () => {
  const url = `${baseUrl}/res/assessment-spec`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const params = {
    method: "GET",
    headers: headers,
  };
  const request = new Request(url, params);

  return fetch(request).then(response => response.json());
};

export { saveAssessment, getAssessment };
