import React from "react";
import PropTypes from "prop-types";
import Input from "./Input";

const NumberInput = ({ value, onChange }) => {
  return (
    <Input
      value={value}
      inputmode="numeric"
      onChange={onChange}
    ></Input>
  );
};

NumberInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberInput;
