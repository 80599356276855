import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import SectionTitle from "./SectionTitle";
import SectionSubtext from "./SectionSubtext";
import SectionSubheading from "./SectionSubheading";
import SectionQuestions from "./SectionQuestions";
import NavButtons from "./NavButtons";

const Section = ({
  prefix,
  id,
  heading,
  subtext,
  subheading,
  questions,
  updateAnswer,
  answers,
  nextSection,
}) => {
  const [errors, setErrors] = useState({});
  const fieldPrefix = `${prefix}.${id}`;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [errors]);

  const handleNext = () => {
    let newErrors = {};
    questions.forEach(question => {
      const answer = answers[`${fieldPrefix}.${question.id}`];

      // separate validation for hidden questions
      if (question.visibility !== "") {
        let parentQuestion = questions.filter(e => {
          return answers[`${fieldPrefix}.${e.id}`] === question.visibility.value
        })
        parentQuestion.forEach(() => {
          if (parentQuestion[0].visibility.value !== answers[`${fieldPrefix}.${question.visibility.refID}`] && (typeof answer === "undefined" || answer === "")) {
            newErrors[question.id] = "This field is required";
            return;
          } 
        })
      }  

      if (question.visibility === "") {
        if (typeof answer === "undefined" || answer === "") {
          newErrors[question.id] = "This field is required";
          return;
        }
      }

      if (answer && (question.type === "number" && !answer.match(/^[\d.]*$/))) {
        newErrors[question.id] = "Please enter just a number";
      }
    });

    if (Object.entries(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    nextSection();
  };

  return (
    <>
      {heading && <SectionTitle>{heading}</SectionTitle>}
      {subheading && <SectionSubheading>{subheading}</SectionSubheading>}
      {subtext && <SectionSubtext>{subtext}</SectionSubtext>}
      <SectionQuestions
        {...{ fieldPrefix, questions, updateAnswer, answers, errors }}
      />
      <NavButtons next={handleNext} />
    </>
  );
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  subtext: PropTypes.string,
  questions: PropTypes.any,
  updateAnswer: PropTypes.func.isRequired,
  answers: PropTypes.any,
  // nav
  nextSection: PropTypes.func.isRequired,
};

export default Section;
