import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import sha256 from "crypto-js/sha256";

let slug = window.location.pathname.substring(1);

if (slug === "") {
  slug = sha256(new Date().toISOString()).toString().slice(0, 35);
  window.history.replaceState(null, "", "/" + slug);
}

ReactDOM.render(
  <React.StrictMode>
    <App clientSlug={slug} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
