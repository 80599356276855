import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classnames from "classnames";

import ChevronDownInactive from "./chevron-grey.svg";
import ChevronDownActive from "./chevron-green.svg";

const classname = "select-control";

const Combobox = ({ value, options, onChange, error }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = newValue => {
    setIsOpen(false);
    onChange(newValue);
  };

  const flyoutClass = classnames(`${classname}__options-flyout`, {
    open: isOpen,
  });
  const overlayClass = classnames(`${classname}__overlay`, { open: isOpen });
  const placeholderClass = classnames(`${classname}__placeholder`, {
    filled: value !== "",
  });

  const optionElems = options.map(option => (
    <div
      className={`${classname}__option`}
      data-value={option}
      key={option}
      onClick={() => handleChange(option)}
    >
      {option}
    </div>
  ));
  const hasError = error !== "";
  const rootClassName = classnames(classname, {
    open: isOpen,
    error: hasError,
  });

  return (
    <ComboboxWrapper>
      <div className={rootClassName} onClick={() => setIsOpen(!isOpen)}>
        <object
          aria-label="arrow"
          type="image/svg+xml"
          className={`${classname}__chevron`}
          data={isOpen ? ChevronDownActive : ChevronDownInactive}
        />
        <div className={placeholderClass}>{value || "Select..."}</div>
        <div className={overlayClass} />
        <div className={flyoutClass}>{optionElems}</div>
      </div>
      {hasError && <div className={`${classname}__error`}>{error}</div>}
    </ComboboxWrapper>
  );
};

Combobox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,

  error: PropTypes.string,
};

Combobox.defaultProps = {
  error: "",
};

export default Combobox;

const ComboboxWrapper = styled.div`
  .select-control {
    border: 1px solid var(--grey04);
    border-radius: 6px;
    background-color: var(--white);
    padding: 0.75rem 1rem;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    height: 48px;

    &.open {
      box-shadow: 0 0 0 8px rgba(var(--brandGreenRGB), 0.3);
      .select-control__chevron {
        transform: rotate(180deg);
      }
    }

    &:hover {
      border: 1px solid var(--brandGreen);
    }

    &__placeholder {
      color: var(--grey04);
      &.filled {
        color: var(--grey12);
      }
    }

    &__chevron {
      float: right;
      height: 24px;
    }

    &__overlay {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;

      &.open {
        display: block;
      }
    }

    &__options-flyout {
      display: none;
      position: absolute;
      top: 4rem;
      left: 0;
      border-radius: 6px;
      background-color: white;
      width: 100%;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      z-index: 1;

      &.open {
        display: block;
      }
    }

    &__option {
      height: 64px;
      padding-left: 1rem;
      box-sizing: border-box;
      line-height: 60px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--grey04);
      }

      &:hover {
        background: var(--grey01);
      }
    }
  }
`;
