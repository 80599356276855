import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Intro from "./Intro";
import Assessment from "./Assessment";
import logo from "./logo.svg";
import Loading from "./Loading";

import { getAssessment } from "./api";

const App = ({ clientSlug }) => {
  const [assessment, receiveAssessment] = useState();
  const [started, setStarted] = useState(false);
  const goToAssessment = () => setStarted(true);

  useEffect(() => {
    getAssessment().then(res => receiveAssessment(res));
  }, []);

  if (!assessment) {
    return (
      <>
        <Logo src={logo} />
        <Loading />
      </>
    );
  }

  return (
    <>
      <Logo src={logo} />
      {started ? (
        <Assessment {...{ clientSlug, assessment }} />
      ) : (
        <Intro goToAssessment={goToAssessment} />
      )}
    </>
  );
};

App.propTypes = {
  clientSlug: PropTypes.string.isRequired,
};

export default App;

const Logo = styled.img`
  display: block;
  margin: 4rem auto 0;
`;
