import styled from "styled-components";

const Input = styled.input`
  box-sizing: border-box;
  font-family: "Brandon Text", sans-serif;
  height: 48px;
  border: 1px solid var(--grey04);
  border-radius: 6px;
  padding: 0;
  padding-left: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  font-size: 16px;

  &:focus {
    border-color: var(--brandGreen);
    border-radius: 6px;
    box-shadow: 0 0 0 8px rgba(var(--brandGreenRGB),0.3);
    outline: none;
  }
  &:hover {
    border-color: var(--brandGreen);
  }
  &:visited {
    border-color: var(--grey05);
  }
  &:valid {
    border-color: var(--grey05);
  }
`;

export default Input;
