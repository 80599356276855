import styled from "styled-components";

const Card = styled.div`
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  padding: 3.5rem;

  @media(max-width: 768px) {
    padding: 1.5rem;
  }
}
`;

export default Card;
