import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import classnames from "classnames";

const RadioButton = ({ id, value, options, onChange }) => {
  return options.map(opt => (
    <RadioButtonWrapper key={id + opt}>
      <label className={classnames("container", { checked: value === opt })}>
        {opt}
        <input
          type="radio"
          name={id}
          value={opt}
          checked={value === opt}
          onChange={onChange}
        ></input>
        <span className="checkmark"></span>
      </label>
    </RadioButtonWrapper>
  ));
};

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButton;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;

  .container {
    color: var(--grey07);
    &.checked {
      color: var(--grey12);
    }
    &:hover {
      color: var(--grey12);
    }
  }
  // hide the default radio button
  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  // create a custom radio button
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid var(--brandGreen);
  }

 
  // when the radio button is checked, add a green background
  & input:checked ~ .checkmark {
    background-color: var(--brandGreen);
  }

  // when the radio button is checked & hovered
  & input:checked:hover ~ .checkmark {
    box-shadow: 0px 0px 3px 4px rgba(var(--brandGreenRGB), 0.15);
  }

  // when the radio button is focused
  & input:checked:focus ~ .checkmark {
    box-shadow: 0px 0px 2px 4px rgba(var(--brandGreenRGB), 0.25);
  }

  // when the radio button is not checked and in focus
  & input:not(:checked):hover ~ .checkmark {
    background-color: rgba(var(--brandGreenRGB), 0.10);
  }
  
  // when the radio button is focused
  & input:not(:checked):focus ~ .checkmark {
    box-shadow: 0px 0px 2px 4px rgba(var(--brandGreenRGB), 0.25);
  }

  /* inner dot/circle - hidden when not checked */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* show dot/circle when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* style the dot/circle */
  .container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--white);
  }
`;
