const websites = {
  id: "websites",
  heading: "Your website",
  type: "multiple",
  prompt: "How many websites do you have for this company?",
  itemName: "Website",
  questions: [
    { id: "url", prompt: "What is the URL to the website?", type: "url" },
    {
      id: "purpose",
      prompt: "What is the purpose of the site?",
      type: "string",
    },
    {
      id: "primary-site",
      prompt: "Is this the primary site of your company?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "relation",
      num: "2a",
      prompt: "How is it related to your business?",
      type: "string",
      visibility: { refID: "primary-site", value: "No" },
    },
    {
      id: "exit-intent",
      prompt: "Do you have an exit intent popup on your website?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "exit-intent-effective",
      num: "3a",
      prompt: "How well does you exit intent popup work?",
      type: "radio",
      options: ["Not sure", "Not great", "Ok", "Great"],
      visibility: { refID: "exit-intent", value: "Yes" },
    },
    {
      id: "resource-offer",
      prompt: "Do you offer any free resources as an opt-in?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "resource-count",
      num: "4a",
      prompt: "How many free resources do you offer?",
      type: "number",
      visibility: { refID: "resource-offer", value: "Yes" },
    },
    {
      id: "resource-downloads",
      num: "4b",
      prompt: "How many downloads did you get for all of these last month?",
      type: "number",
      visibility: { refID: "resource-offer", value: "Yes" },
    },
    {
      id: "resource-thank-you",
      num: "4c",
      prompt: "How do you use the thank you page for the download?",
      type: "radio",
      options: ["Thank them", "Product offer", "Ask them to share"],
      visibility: { refID: "resource-offer", value: "Yes" },
    },
    {
      id: "unique-visitors",
      prompt: "How many unique visitors did you have last month?",
      type: "number",
    },
    {
      id: "most-visited-pages",
      prompt: "What are your 5 most visited pages?",
      type: "textarea",
    },
    {
      id: "blog",
      prompt: "Do you have a blog on the site?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "blog-frequency",
      num: "7a",
      prompt: "How often do you publish?",
      type: "textarea",
      visibility: { refID: "blog", value: "Yes" },
    },
    {
      id: "blog-count",
      num: "7b",
      prompt: "How many blog posts have you published in the last year?",
      type: "number",
      visibility: { refID: "blog", value: "Yes" },
    },
    {
      id: "blog-time",
      num: "7c",
      prompt: "How much time do you spend writing blog posts each week?",
      type: "number",
      visibility: { refID: "blog", value: "Yes" },
    },
    {
      id: "blog-happiness",
      num: "7d",
      prompt: "Are you happy with your blogging strategy?",
      type: "radio",
      options: ["Yes", "No"],
      visibility: { refID: "blog", value: "Yes" },
    },
    {
      id: "blog-age",
      num: "7e",
      prompt: "How many months have you been publishing?",
      type: "number",
      visibility: { refID: "blog", value: "Yes" },
    },
    {
      id: "seo-skills",
      prompt: "How would you rate your SEO skills?",
      type: "radio",
      options: ["Newbie", "Solid", "Expert"],
    },
  ],
};

const emailLists = {
  id: "email-lists",
  heading: "Your email list(s)",
  type: "multiple",
  prompt: "How many email lists do you have?",
  itemName: "Email list",
  questions: [
    {
      id: "name",
      prompt: "Name of your email list",
      type: "string",
    },
    {
      id: "subs",
      prompt: "How many subscribers are on it?",
      type: "number",
    },
    {
      id: "monthly-subs",
      prompt: "How many subscribers did you get last month?",
      type: "number",
    },
    {
      id: "sources",
      prompt: "What are the 3-5 primary sources of those subscribers?",
      type: "textarea",
    },
    {
      id: "relevancy",
      prompt:
        "Are your subscribers relevant to the primary product you want to sell?",
      type: "radio",
      options: ["Yes", "Somewhat", "Not at all"],
    },
    {
      id: "selling-frequency",
      prompt: "How often do you send non-sales emails?",
      type: "radio",
      options: ["Weekly", "Monthly", "Daily", "Irregular", "Never"],
    },
    {
      id: "more-context",
      prompt: "Give me more context no how this works",
      type: "textarea",
    },
    {
      id: "click-thru",
      prompt: "What is the average click-through rate of the last 5 emails?",
      type: "string",
    },
    {
      id: "open-rate",
      prompt: "What is the average open rate of the last 5 emails?",
      type: "string",
    },
    {
      id: "hours-per-week",
      prompt: "How many hours do you spend writing emails each week?",
      type: "number",
    },
    {
      id: "confidence",
      prompt: "How happy and confident are you with your current email plan?",
      type: "radio",
      options: [
        "Very happy and confident",
        "It's going well, I guess",
        "Needs improvement",
      ],
    },
    {
      id: "welcome-sequence",
      prompt: "Do you have a welcome sequence for people who sign up?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "sell-in-welcome-sequence",
      prompt: "Do you ask them to buy your product in your welcome sequence?",
      type: "radio",
      options: ["Yes", "No"],
      visibility: { refID: "welcome-sequence", value: "Yes" },
    },
    {
      id: "welcome-sequence-confidence",
      prompt: "How confident are you in this sequence?",
      type: "radio",
      options: ["I love it!", "It's OK", "Not very"],
      visibility: { refID: "welcome-sequence", value: "Yes" },
    },
  ],
};
const podcast = {
  id: "podcast",
  heading: "Podcast",
  questions: [
    {
      id: "have-one",
      prompt: "Do you have a podcast?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "url",
      prompt: "Link to the podcast",
      type: "url",
      visibility: { refID: "have-one", value: "Yes" },
    },
    {
      id: "publish-frequency",
      prompt: "How often do you publish?",
      type: "string",
      visibility: { refID: "have-one", value: "Yes" },
    },
    {
      id: "average-downloads",
      prompt: "Average downloads per episode",
      type: "number",
      visibility: { refID: "have-one", value: "Yes" },
    },
    {
      id: "hours-per-week",
      prompt:
        "How many hours per week do you spend on content creation for your podcast?",
      type: "number",
      visibility: { refID: "have-one", value: "Yes" },
    },
  ],
};

const partnerships = {
  id: "partnerships",
  heading: "Partnerships",
  questions: [
    {
      id: "done-any",
      prompt: "Have you done any partnerships on the last 12 months?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "summary",
      prompt: "Please list and tell me about them",
      type: "textarea",
      visibility: { refID: "done-any", value: "Yes" },
    },
    {
      id: "pa-student",
      prompt: "Have you previously taken the Partnerhip Accelerator",
      type: "radio",
      options: ["Yes", "No"],
      visibility: { refID: "done-any", value: "Yes" },
    },
    {
      id: "dream-five",
      prompt:
        "Who are the 5 companies you WOULD like to promote your business?",
      type: "textarea",
      visibility: { refID: "done-any", value: "Yes" },
    },
  ],
};

const webinars = {
  id: "webinars",
  heading: "Webinars",
  questions: [
    {
      id: "running",
      prompt: "Do you currently run webinars to promote your business?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "cadence",
      prompt: "How often do you run webinars?",
      type: "radio",
      options: ["Weekly", "Monthly", "Quarterly", "Irregularly"],
      visibility: { refID: "running", value: "Yes" },
    },
    {
      id: "confidence",
      prompt: "How confident are you that your webinars convert well?",
      type: "radio",
      options: [
        "I've never sold from my webinar",
        "Things are going ok, I guess",
        "I know how much I will make everytime I run a webinar",
      ],
      visibility: { refID: "running", value: "Yes" },
    },
  ],
};

const salesProcess = {
  id: "sales-process-section",
  heading: "Tell me about your sales process",
  questions: [
    {
      id: "high-level",
      prompt: "Describe your sales process at a high level",
      type: "textarea",
    },
    {
      id: "monthy-promotions",
      prompt: "Do you do any monthly promotions to your email list?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "monthly-promotions-desc",
      prompt: "Describe how that works, results, and what you do",
      type: "textarea",
      visibility: { refID: "monthly-promotions", value: "Yes" },
    },
  ],
};

const zoomOut = {
  id: "zoom-out",
  heading: "Let's wrap up by zooming back out",
  questions: [
    {
      id: "business-else",
      prompt:
        "Is there anything else about your business that I need to know about?",
      type: "textarea",
    },
  ],
};

const marketing = {
  id: "marketing",
  heading: "Section 3: Tell me about your marketing",
  sections: [
    // socialMedia,
    websites,
    emailLists,
    podcast,
    partnerships,
    webinars,
    salesProcess,
    zoomOut,
  ],
};
const businessIntro = {
  id: "business-intro",
  heading:
    "If you have more than one business, you will have the opportunity to share about another one later in the assessment. Right now, focus on only one, beginning with the one that lights you up the most.",
  questions: [
    {
      id: "name",
      prompt: "What is the name of your first business?",
      type: "string",
    },
    {
      id: "overview",
      prompt: "Give me a brief overview of your business. How does it work?",
      type: "textarea",
    },
    {
      id: "why-start",
      prompt: "Why did you start your business?",
      type: "text",
    },
    {
      id: "what-problem",
      prompt: "What is the problemn you solve for your customers?",
      type: "textarea",
    },
    {
      id: "why-you",
      prompt: "Why did YOU decide to solve this problem?",
      type: "textarea",
    },
    {
      id: "full-or-part-time",
      prompt: "Do you work on this business full time or part time?",
      type: "radio",
      options: ["Full-time", "Part-time"],
    },
    {
      id: "how-many-hours",
      prompt: "How many hours per week do you work on this business?",
      type: "number",
    },
    {
      id: "have-team",
      prompt: "Do you have a team?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "about-team",
      prompt: "Tell me about the team",
      type: "textarea",
      visibility: {
        refID: "have-team",
        value: "Yes",
      },
    },
    {
      id: "team-roles",
      prompt: "What does each of them do?",
      type: "textarea",
      visibility: {
        refID: "have-team",
        value: "Yes",
      },
    },
    {
      id: "twelve-month-picture",
      prompt:
        "Paint a picture of what you want your business to look like in 1 year?",
      type: "textarea",
    },
  ],
};

const products = {
  id: "products",
  heading: "Your products",
  subheading:
    "Share with us your top revenue-creating products for your business.",
  type: "multiple",
  prompt: "How many products or services do you offer?",
  itemName: "Product",
  questions: [
    {
      id: "name",
      prompt: "Product name",
      type: "string",
      inTable: true,
    },
    {
      id: "type",
      prompt: "Product type",
      type: "combo",
      inTable: true,
      options: [
        "Membership",
        "Course",
        "Saas",
        "1:1 Coaching",
        "Group coaching",
        "Consulting",
        "Physical product",
        "In person workshop",
      ],
      allowOther: true,
    },
    {
      id: "prices",
      prompt: "What is the price(s)?",
      type: "textarea",
    },
    {
      id: "url",
      prompt: "URL to sales page",
      type: "url",
    },
    { id: "problem", prompt: "Problem it solves", type: "string" },
    {
      id: "best-fit-customer",
      prompt: "Who is the best-fit customer for this product?",
      type: "textarea",
    },
    {
      id: "fullfillment",
      prompt: "How does fulfillment work for this product?",
      type: "textarea",
    },
    {
      id: "have-guarantee",
      prompt: "Does this product have a guarantee?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "guarantee-desc",
      num: "8a",
      prompt: "What is the guarantee?",
      type: "textarea",
      visibility: { refID: "have-guarantee", value: "Yes" },
    },
    {
      id: "ever-sold",
      prompt: "Have you ever sold this product?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "annual-revenue",
      num: "9a",
      prompt: "How much revenue last year?",
      type: "number",
      visibility: { refID: "ever-sold", value: "Yes" },
    },
    {
      id: "monthly-revenue",
      num: "9b",
      prompt: "How much revenue last month?",
      type: "number",
      visibility: { refID: "ever-sold", value: "Yes" },
    },
    {
      id: "new-clients",
      num: "9c",
      prompt: "How many new clients in the past year?",
      type: "number",
      visibility: { refID: "ever-sold", value: "Yes" },
    },
    {
      id: "sales-process",
      num: "9d",
      prompt: "How do you typically sell this product?",
      type: "radio",
      options: ["Sales calls", "Recurring", "Launch", "Facebook Ads", "Other"],
      visibility: { refID: "ever-sold", value: "Yes" },
    },
    {
      id: "sales-process-other",
      num: "9e",
      prompt: "How else do you sell this product?",
      type: "string",
      visibility: { refID: "sales-process", value: "Other" },
    },
    {
      id: "proven-sales",
      num: "9f",
      prompt:
        "Have you proven to yourself that you know how to sell this product?",
      type: "radio",
      options: ["Yes", "No"],
      visibility: { refID: "sales-process", value: "Other" },
    },
    {
      id: "how-proven",
      num: "9g",
      prompt: "How so?",
      type: "string",
      visibility: { refID: "proven-sales", value: "Yes" },
    },
    {
      id: "excitement-level",
      prompt: "How excited are you about this product?",
      type: "radio",
      options: ["Very excited", "Kind of excited", "Not very much"],
    },
    {
      id: "impact-level",
      prompt: "How much impact can you envision having with this product?",
      type: "radio",
      options: [
        "I'm changing the world!",
        "Kind of impactful",
        "Not very much",
      ],
    },
    {
      id: "potential-earnings",
      prompt: "How much money can you envision making with this product?",
      type: "radio",
      options: [
        "All my dreams are coming true",
        "I'm making ends meet",
        "Not very much",
      ],
    },
    {
      id: "upcoming-promotion",
      prompt:
        "Do you have a promotion or launch planned for this product in the next 90 days?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      id: "upcoming-promotion-date",
      prompt: "When is the promotion scheduled?",
      type: "date",
      visibility: { refID: "upcoming-promotion", value: "Yes" },
    },
    {
      id: "upcoming-promotion-description",
      prompt: "Can you describe the promotion?",
      type: "textarea",
      visibility: { refID: "upcoming-promotion", value: "Yes" },
    },
    {
      id: "promotions",
      itemName: "Promotion",
      type: "multiple",
      prompt:
        "How many sales promotions did you run for this product in the last year?",
      questions: [
        {
          id: "date",
          prompt: "What was the date of this promotion?",
          type: "date",
        },
        {
          id: "performance",
          prompt: "At a high level, how did this promotion go?",
          type: "textarea",
        },
        {
          id: "revenue",
          prompt: "How much revenue did this promotion generate?",
          type: "number",
        },
        {
          id: "mechanism",
          prompt: "How did the promotion work? (email sequence? Podcast? Etc.)",
          type: "textarea",
        },
      ],
    },
  ],
};

const businessSummary = {
  id: "business-summary",
  heading: "One last thing about your products",
  questions: [
    {
      id: "most-exciting-product",
      prompt:
        "Of all the products you've listed out, if you could only focus on one to hit your goal, whch would it be?",
      type: "string",
    },
  ],
};

const businessOne = {
  id: "business-one",
  heading: "Section 2: Tell us about your business and the product(s) you sell",
  sections: [businessIntro, products, businessSummary],
};

const fiveToStart = {
  id: "fiveToStart",
  heading:
    "Give me a quick high level overview of you and your business and then we’ll dive deeper in to your business(es) the next section.",
  questions: [
    {
      id: "number-one-goal",
      prompt: "What is your #1 goal for your business?",
      type: "radio",
      options: ["Increasing leads", "Increasing sales", "Other"],
    },
    {
      id: "follow-up-goal",
      num: "1a",
      prompt: "Follow-up: What is the specific goal?",
      type: "textarea",
      visibility: {
        refID: "number-one-goal",
        value: "Other",
      },
    },
    {
      id: "follow-up-goal-why",
      num: "1b",
      prompt: "Follow-up: Why?",
      type: "textarea",
      visibility: {
        refID: "number-one-goal",
        value: "Other",
      },
    },
    {
      id: "impact",
      prompt: "How would accomplishing this impact your life?",
      type: "textarea",
    },
    {
      id: "your-plan",
      prompt:
        "If you hired yourself to make a marketing plan to achieve that goal, what would the plan be?",
      type: "textarea",
    },
    {
      id: "already-tried",
      prompt:
        "What are 3 things you've tried to do, but failed at, to achieve this goal?",
      type: "textarea",
    },
    {
      id: "why-join",
      prompt: "Why did you decide to join Growth University?",
      type: "textarea",
    },
    {
      id: "family",
      prompt:
        "Tell me about you personally. (Family situation. Health. Anything I should know)",
      type: "textarea",
    },
  ],
};

const overview = {
  id: "overview",
  heading: "Section 1",
  sections: [fiveToStart],
};

const assessment = [overview, businessOne, marketing];

export default assessment;
