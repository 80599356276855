import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classnames from "classnames";

import Label from "./Label";
import ErrMsg from "./ErrorMsg";

const Question = ({ index, prompt, children, error }) => {
  return (
    <StyledQuestion className={classnames({ "has-error": error })}>
      <Label {...{ index, prompt }} />
      {children}
      {error && <ErrMsg>{error}</ErrMsg>}
    </StyledQuestion>
  );
};

Question.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  prompt: PropTypes.string.isRequired,
  children: PropTypes.node,
  error: PropTypes.string,
};

export default Question;

const StyledQuestion = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;

  &.has-error input {
    border-color: red;
  }
`;
