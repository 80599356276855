import styled from "styled-components";

const SectionSubtext = styled.div`
  margin: 8px 0 16px;
  color: #263238;
  font-family: "Brandon Text";
  font-size: 16px;
  border-radius: 4px;
  background: linear-gradient(90deg, #FFFFFF 0%, #F9FAFA 100%);
  `;

export default SectionSubtext;
