import styled from "styled-components";

const SectionHeading = styled.div`
  color: var(--grey09);
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 2rem;
`;

export default SectionHeading;
