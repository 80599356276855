import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { MessageCard, Icon, Heading, Content } from "./messages";
import ProgressBar from "./ProgressBar";
import { PrimaryButton } from "./buttons";

const Thanks = ({ slug, totalPages, goToSection }) => {
  return (
    <ThanksWrapper>
      <ProgressBar
        totalPages={totalPages + 1}
        currentPage={totalPages}
        totalSections={0}
        currentSection={0}
        goToSection={goToSection}
      />
      <MessageCard>
        <Icon>
          <span role="img" aria-label="Heart envelope emoji">
            💌
          </span>
        </Icon>
        <Heading>And it&apos;s done!</Heading>
        <Content>
          You can review your answers by navigating the progress bar above or
          send your assessment right now.
        </Content>
        <PrimaryButton
          onClick={() => {
            window.location = `https://university.growthtools.com/assessment/thank-you?user-slug=${slug}`;
          }}
        >
          Send assessment
        </PrimaryButton>
      </MessageCard>
    </ThanksWrapper>
  );
};

Thanks.propTypes = {
  slug: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  goToSection: PropTypes.func.isRequired,
};

export default Thanks;

const ThanksWrapper = styled.div`
  max-width: 888px;
  margin: 3rem auto;
`;
