import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { MessageCard, Icon, Heading, Content } from "./messages";
import { PrimaryButton } from "./buttons";

const Intro = ({ goToAssessment }) => {
  return (
    <StyledMessageCard>
      <Icon>
        <span role="img" aria-label="Party hat emoji">
          🥳
        </span>
      </Icon>
      <Heading>Let&apos;s get to know you</Heading>
      <Content>
        Your answers help us build
        <br /> your coaching program
      </Content>
      <PrimaryButton onClick={goToAssessment}>Start right now</PrimaryButton>
    </StyledMessageCard>
  );
};

Intro.propTypes = {
  goToAssessment: PropTypes.func.isRequired,
};

export default Intro;

const StyledMessageCard = styled(MessageCard)`
  margin-top: 6.5rem;
`;
