import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useImmer } from "use-immer";

import Card from "./Card";
import ProgressBar from "./ProgressBar";
import Section from "./Section";
import MultiSection from "./MultiSection";
import Thanks from "./Thanks";

import { saveAssessment } from "./api";
let saveAssessmentTimeout;

const localStorageKey = "gu-assessment";

const Assessment = ({ clientSlug, assessment }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const nextPage = () => setPageIndex(pageIndex + 1);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [answers, updateAnswer] = useImmer({});
  const answersRef = useRef(answers);
  answersRef.current = answers;

  useEffect(() => {
    const slugKey = localStorageKey + clientSlug;
    if (localStorage.getItem(slugKey)) {
      const existingState = JSON.parse(localStorage.getItem(slugKey));
      setPageIndex(existingState.pageIndex);
      setSectionIndex(existingState.sectionIndex);
      updateAnswer(() => existingState.answers);
    }
  }, [updateAnswer, clientSlug]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pageIndex, sectionIndex]);

  const goToSection = (page, section) => {
    setPageIndex(page);
    setSectionIndex(section);
  };

  const setDeepAnswer = (field, value) => {
    updateAnswer(draft => {
      draft[field] = value;
    });
    clearTimeout(saveAssessmentTimeout);
    saveAssessmentTimeout = setTimeout(triggerSave, 750);
  };

  const triggerSave = () => {
    localStorage.setItem(
      localStorageKey + clientSlug,
      JSON.stringify({
        pageIndex: pageIndex,
        sectionIndex: sectionIndex,
        answers: answersRef.current,
      })
    );

    saveAssessment({
      clientSlug,
      answers: answersRef.current,
      spec: assessment,
    });
  };

  if (pageIndex >= assessment.length) {
    return (
      <Thanks
        totalPages={assessment.length}
        goToSection={goToSection}
        slug={clientSlug}
      />
    );
  }

  const page = assessment[pageIndex];
  const section = page.sections[sectionIndex];

  const nextSection = () => {
    const nextIndex = sectionIndex + 1;
    if (nextIndex >= page.sections.length) {
      nextPage();
      setSectionIndex(0);
      return;
    }

    setSectionIndex(nextIndex);
  };

  return (
    <AppWrapper>
      <ProgressBar
        totalPages={assessment.length}
        currentPage={pageIndex}
        totalSections={page.sections.length}
        currentSection={sectionIndex}
        goToSection={goToSection}
      />
      <Card>
        <PageHeading>{page.sectionnumber}</PageHeading>
        {page.title && (section.type !== "multiple" && sectionIndex < 1) && <PageTitle>{page.title}</PageTitle>}
        {page.subtitle && (section.type !== "multiple" && sectionIndex < 1) && <PageSubHeading>{page.subtitle}</PageSubHeading>}
        {section.type === "multiple" ? (
          <MultiSection
            {...section}
            prefix={page.id}
            updateAnswer={setDeepAnswer}
            {...{ answers, nextSection }}
          />
        ) : (
          <Section
            {...section}
            prefix={page.id}
            updateAnswer={setDeepAnswer}
            {...{ answers, nextSection }}
          />
        )}
      </Card>
    </AppWrapper>
  );
};

Assessment.propTypes = {
  clientSlug: PropTypes.string.isRequired,
  assessment: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Assessment;

const AppWrapper = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 3rem 5rem;
  background-color: var(--grey01);
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const PageTitle = styled.div`
  color: var(--grey12);
  font-size: 32px;
  font-weight: 900;
  margin: 8px 0 16px;
`;

const PageHeading = styled.div`
  color: var(--grey12);
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const PageSubHeading = styled.div`
  color: var(--grey09);
  font-size: 1.5rem;
  line-height: 2rem;
`;
